import { useState } from "react";
import "./Menu.scss";
import Logo from "../../assets/icons/logo-footer2.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";
import { MenuProps, navigationProps } from "../../types/interfaces";

const Header: React.FC<MenuProps> = ({
  isCurrentLanding,
  redirectToNavigate,
}: MenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const sections: navigationProps[] = [
    /*{
      label: "Mint now",
      toLink: "/mint",
    },*/
    {
      label: "About",
      toLink: "/#about-page",
    },
    {
      label: "Roadmap",
      toLink: "/#roadmap-page",
    },
    {
      label: "FAQ",
      toLink: "/#faq-page"
    },
    {
      label: "Team",
      toLink: "/#team",
    },
    {
      label: "Provenance",
      toLink: "/provenance"
    }
  ];
  return (
    <>
      <nav
        id="header"
        className="navbar navbar-expand-lg navbar-light bg-transparent"
      >
        <div className="container-fluid">
          <a className="navbar-brand text-center" href="/">
            <img
              src={Logo}
              width={180}
              alt="cryptoeaglesclub-logo"
              className="d-inline-block align-text-top img-mobile d-lg-none"
            />
            <img
              src={Logo}
              width={180}
              alt="cryptoeaglesclub-logo"
              className="d-none d-lg-block"
            />
          </a>
          <button
            onClick={() => toggleOpen()}
            className="navbar-toggler btn-toggle"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {!isOpen && <MenuIcon sx={{ color: "white" }} />}
            {isOpen && <Close sx={{ color: "white" }} />}
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto">
              {sections.map((menu_item, i) => (
                <li key={i} className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href={menu_item.toLink}
                  >
                    {menu_item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
