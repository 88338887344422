import "./slick.scss";
import Slider from "react-slick";

const SlickElement = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2500,
    cssEase: "linear",
  };
  return (
    <div className="d-none d-lg-block" id="slick-element">
      <Slider {...settings}>
        <div>
          <h3>PL (WL) IS NOW AVAILABLE, JOIN ON DISCORD!</h3>
        </div>
        <div>
          <h3>EACH CRYPTO EAGLE COST FOR WL WILL BE 0.015 ETH</h3>
        </div>
        <div>
          <h3>WHITELIST SPOTS ARE AVAILABLE</h3>
        </div>
        <div>
          <h3>PLAY GAMES IN OUR DISCORD!</h3>
        </div>
      </Slider>
    </div>
  );
};

export default SlickElement;
