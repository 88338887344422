import "./App.css";
import "../src/assets/theme/global.css";
import PagesRoutes from "./routes/routes";

const App = () => {
  return (
    <>
      <PagesRoutes />
    </>
  )
}

export default App;
