import { Tab, Tabs, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useState } from "react";
import { TabPanelProps } from "../../types/interfaces";
import EagleShirt from "../../assets/eagles/eagle-with-shirt.png";
import "./Roadmap.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const TabsContainer = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#be1622",
  },
});

const AntTabs = styled(Tab)({
  fontSize: 75,
  color: "#ffffff91",
  fontFamily: "Heaters",
  "&.Mui-selected": {
    color: "#be1622",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
});

const AntMobileTabs = styled(Tab)({
  fontSize: 40,
  color: "#ffffff91",
  fontFamily: "Heaters",
  "&.Mui-selected": {
    color: "#be1622",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
});

const RoadMapPage = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const { width } = useWindowDimensions();
  const handleChange = (tab: React.SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);

  const TabPanel = (props: TabPanelProps): JSX.Element => (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`roadmaptab_${props.index}`}
    >
      {props.value === props.index && (
        <Box sx={{ p: 3 }}>
          <Typography>{props.children}</Typography>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <section id="roadmap-page">
        <div className="row text-center">
          <div className="col">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                {width <= 481 ? (
                  <TabsContainer
                    value={currentTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                  >
                    <AntMobileTabs label="Roadmap" value={1} />
                    <AntMobileTabs label="After mint" value={2} />
                  </TabsContainer>
                ) : (
                  <TabsContainer
                    value={currentTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                  >
                    <AntTabs label="Roadmap" value={1} />
                    <AntTabs label="After mint" value={2} />
                  </TabsContainer>
                )}
              </Box>
              <TabPanel value={currentTab} index={1}>
                <div id="scrollable" className="content-keypoint">
                  <div className="content">
                    <div className="percent">Stage 1</div>
                    <p>
                      10 Crypto Eagles will be given away when the Official Crypto Eagles NFT discord reaches 5000 members.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">Stage 2</div>
                    <p>
                      30% of the funds accumulated from the mint will be used to fund the community wallet with staking.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">Stage 3</div>
                    <p>
                      20% of the funds accumulated from the mint will be used to marketing in order to help with the longevity of the project.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">Stage 4</div>
                    <p>
                      $60.000 will be donated to a charity of the community's choice.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">Stage 5</div>
                    <p>
                     We will implement a fund distribution system to send eth funds to each holder to achieve stage 2
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">Stage 6</div>
                    <p>
                      We are going to reward each holder with collaborations with other projects.
                    </p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <div id="scrollable" className="content-keypoint">
                  <div className="content">
                    <div className="percent">I</div>
                    <p>
                      A 5% royalty derived from the secondary market will be set
                      for reinvestment in branding, Club Eagles events,
                      community awards and operational expansion of the project.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">II</div>
                    <p>
                      30% of all royalties derived from the secondary market
                      will belong to the Eagles holders. The community will have
                      the power to propose and decide the management of these
                      resources for the benefit of all the Eagles.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">III</div>
                    <p>
                      Permanent investment in branding to promote the
                      internationalization and positioning of the brand in the metaverse.
                    </p>
                  </div>
                  <div className="content">
                    <div className="percent">IV</div>
                    <p>
                      Partnership and collaborations with brands for the launch
                      of exclusive limited edition merchandise for all holders.
                    </p>
                  </div>
                </div>
              </TabPanel>
            </Box>
          </div>
        </div>
        {/*<img className="eagle-footer" src={EagleShirt} alt="thecryptoeagleclub.com" />*/}
      </section>
    </>
  );
};

export default RoadMapPage;
