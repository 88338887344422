import { SectionMintProps } from "../../types/interfaces";
import "./Mint.scss";
import image from "../../assets/eagles/unknown-eagle.png";

const MintPage = ({
  availableForMinting,
  isWhiteListPeriod,
  presale,
}: SectionMintProps): JSX.Element => {
  return (
    <>
      <div className="space-100" />
      <section id="mint">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              {isWhiteListPeriod && (
                <>
                  <h1 className="cta-title">VIP ACCESS IS AVAILABLE NOW</h1>
                  <div className="space-20"></div>
                </>
              )}
              {!availableForMinting && !presale && (
                <>
                  <h1 style={{ fontSize: 30 }} className="cta-title">
                    THE MOST DANGEROUS NFT CRIMINAL CLUB <br /> IS ALMOST HERE
                  </h1>
                  {/*<img className="d-none d-lg-inline-block" src="https://img1.niftyimages.com/e-_/csyd/d8xr" />*/}
                </>
              )}
            </div>
            {availableForMinting && (
              <>
                <img
                  className="unknown-eagle d-none d-lg-block"
                  src={image}
                  alt="cryptoeaglesclub"
                />
                <h1 className="cta-title">
                  MINT YOUR{" "}
                  <strong style={{ color: "#be1622" }}>CRYPTO EAGLE</strong> NOW
                </h1>
                <div className="col-12">
                  <a
                    id="mint-btn"
                    href="/mint"
                    type="button"
                    className="btn btn-primary"
                  >
                    Mint a Crypto Eagle
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MintPage;
