import { SectionMintProps } from "../../types/interfaces";
import "./Cta.scss";

const CtaPage = ({
  isWhiteListPeriod,
  availableForMinting,
  openSeaLink,
  presale,
}: SectionMintProps): JSX.Element => {
  return (
    <section id="cta-page">
      <div className="container">
        <div className="mb-5 row">
          <div className="col">
            <div id="buy-an-eagle" className="buy-token-container">
              <div className="eagle-bg p-4 m-auto row">
                <div className="m-auto col-lg-3 col-12">
                  <h3 className="title">BUY AN CRYPTO EAGLE</h3>
                </div>
                <div className="m-auto col-lg-4 col-12 offset-lg-1">
                  {isWhiteListPeriod && (
                    <p className="common-p mb-lg-0">
                      The initial sale has not started yet, first we will give a
                      limited spots for whitelist, then we will put a presale
                      date for everyone, remember the price is 0.06 ETH, you'll
                      need your metamask wallet available for minting and max 20&nbsp;
                      <strong>Crypto Eagles</strong> per wallet.
                    </p>
                  )}
                  {!isWhiteListPeriod && availableForMinting && presale && (
                    <p className="common-p mb-lg-0">
                      Mint is available for everyone, the price is 0.06 ETH
                      excluding gas fee, you'll need your metamask wallet
                      available for minting and max 20&nbsp;
                      <strong>Crypto Eagles</strong> per wallet.
                    </p>
                  )}
                  {!isWhiteListPeriod && !availableForMinting && presale && (
                    <p className="common-p mb-lg-0">
                      The initial sale has sold out. To get your&nbsp;
                      <strong>Crypto Eagle</strong>, check out the collection on
                      OpenSea.
                    </p>
                  )}
                  {!isWhiteListPeriod && !availableForMinting && !presale && (
                    <p className="common-p mb-lg-0">
                      The pigeon sale has not started yet, pay atention on Discord
                      for launch.
                    </p>
                  )}
                </div>
                {availableForMinting && (
                  <div className="m-auto col-lg-3 col-12 offset-lg-1">
                    <a id="mint-btn" href="/mint">
                      <button className="btn" type="button">
                        Mint your crypto eagle
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaPage;
