import "./faq.scss";

const FaqPage = (): JSX.Element => {
  return (
    <>
      <div className="space-30"></div>
      <section id="faq-page">
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <h1>FAQ</h1>
              <div className="faq-item">
                <p className="faq-item-header">
                  How much is a Crypto Eagle NFT?
                </p>
                <p className="faq-item-text">
                  Pigeon Sale ( WL ) members may mint a Crypto Eagle for 0.015
                  ETH.
                </p>
                <p className="faq-item-text">
                  Public Sale members may mint for 0.030 ETH.
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">
                  How many Crypto Eagles NFTs are there in circulation?
                </p>
                <p className="faq-item-text">
                  There are a total of 9999 total Crypto Eagles.
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">When is mint?</p>
                <p className="faq-item-text">
                  Our Pigeon Sale mint is on April 15, 2022 7:00 PM UTC
                </p>
                <p className="faq-item-text">
                  Our Public Sale mint is on April 22, 2022 7:00 PM UTC
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">
                  Is there a WL? How can I get on?
                </p>
                <p className="faq-item-text">
                  Our Pigeon List is available now. You can obtain it by being
                  an engaging and positive member on Discord!
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">
                  How many Crypto Eagles can I mint?
                </p>
                <p className="faq-item-text">
                  Pigeon Sale members can mint 10 Crypto Eagles during the
                  Pigeon Sale.
                </p>
                <p className="faq-item-text">
                  Public sale members can mint 20 Crypto Eagles per TXN
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">What utility is offered?</p>
                <p className="faq-item-text">
                  Staking, Giveaways, Royalties, Charity, DAO, Airdrops,
                  Collaborations, Distributions and WL for upcoming projects.
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">
                  I got a DM saying a pre-sale is starting. Is this real?
                </p>
                <p className="faq-item-text">
                  NO! Our staff team will NEVER DM you first.
                </p>
              </div>
              <div className="faq-item">
                <p className="faq-item-header">How do I mint?</p>
                <p className="faq-item-text">
                  Connect your Metamask wallet on our website and choose the
                  amount of Crypto Eagles you wish to mint. Your Crypto Eagles
                  will then appear in your Opensea profile wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-100"></div>
      </section>
    </>
  );
};

export default FaqPage;
