import { useState } from "react";
import "./Bio.scss";

const BioPage = (): JSX.Element => {
  const [toggleOne, setToggleOne] = useState<boolean>(false);
  const [toggleTwo, setToggleTwo] = useState<boolean>(false);
  return (
    <>
      <section id="bio-page">
        <div className="container">
          <div className="row text-center">
            <div className="col-12 col-md-6">
              <div className="accordion" id="accordionExample">
                <div
                  style={{ background: "transparent" }}
                  className="accordion-item"
                >
                  <h2 className="accordion-header" id="headingOne">
                    <div
                      className="card-info"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => setToggleOne(!toggleOne)}
                    >
                      <h1>WHERE DO THEY COME FROM</h1>
                      <button
                        type="button"
                        onClick={() => setToggleOne(!toggleOne)}
                      >
                        {toggleOne ? "Hide info" : "View info"}
                      </button>
                    </div>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse panel-eagle"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      They belongs to Kandor an city founded in the year 1480 by
                      Lord Eagle, an Eagleman owner of a powerful drug
                      trafficking network, but he always had a low profile due
                      to his links with the government, at the beginnings, this
                      city grew as a city full of harmony, as a tourist center
                      and main hideout of the organization. Until different
                      internal organizations of this city overthrew the control
                      of Lord Eagle, now Kandor is a black well full of
                      criminals and is considered as the most dangerous city in
                      the entire planet.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-40 d-md-none"></div>
            <div className="col-12 col-md-6">
              <div className="accordion" id="accordionExample">
                <div
                  style={{ background: "transparent" }}
                  className="accordion-item"
                >
                  <h2 className="accordion-header" id="headingTwo">
                    <div
                      className="card-info"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                      onClick={() => setToggleTwo(!toggleTwo)}
                    >
                      <h1>TECH SPECS</h1>
                      <button
                        type="button"
                        onClick={() => setToggleTwo(!toggleTwo)}
                      >
                        {toggleTwo ? "Hide info" : "View info"}
                      </button>
                    </div>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse panel-eagle"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The Crypto Eagles are generated by an algorithm that
                      will randomly select and sort all the traits based on
                      rarity statistical probabilities and restrictions. This
                      method ensures that each Eagle will be unique and
                      unrepeatable from the start, having a distinctive ID which
                      is stored as an ERC-721 token on the Ethereum (ETH)
                      Blockchain and its image file hosted on Interplanetary
                      File System (IPFS), this guaranties a fully transparent
                      and secure provenance record.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BioPage;
