import "./Team.scss";
import LordEagle from "../../assets/eagles/lord-eagle.png";
import EagleGang from "../../assets/eagles/eagle-shirt.png";
import Kirigon from "../../assets/eagles/kirigon.png";
import Edphos from "../../assets/eagles/edphos.png";

const TeamPage = (): JSX.Element => {
  return (
    <section id="team">
      <div className="container">
        <div className="mb-5 row">
          <div className="col">
            <div className="row">
              <div className="mb-3 col-lg-7 col-12">
                <h1>TEAM</h1>
                <p>
                  <span>Lord Eagle,</span>&nbsp;Founder, Development Company
                  CEO.
                </p>
                <p>
                  <span>Gaby,</span>&nbsp;Designer Artist.
                </p>
                <p>
                  <span>Kirigon</span>&nbsp;Co-founder, Investor.
                </p>
                <p>
                  <span>Edphos</span>&nbsp;Marketing Director.{" "}
                </p>
              </div>
              <div className="col-lg-4 col-12 offset-lg-1">
                <div className="row">
                  <div className="space-60"></div>
                  <div className="pb-2 pr-2 col-6">
                    <img
                      className="img-fluid"
                      src={LordEagle}
                      alt="cryptoeaglesclub"
                    />
                  </div>
                  <div className="pb-2 pr-2 col-6">
                    <img
                      className="img-fluid"
                      src={EagleGang}
                      alt="cryptoeaglesclub"
                    />
                  </div>
                  <div className="pb-2 pr-2 col-6">
                    <img
                      className="img-fluid"
                      src={Kirigon}
                      alt="cryptoeaglesclub"
                    />
                  </div>
                  <div className="pb-2 pr-2 col-6">
                    <img
                      className="img-fluid"
                      src={Edphos}
                      alt="cryptoeaglesclub"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamPage;
