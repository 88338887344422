import { useRoutes } from "react-router-dom";
import Landing from "../views/Landing/Landing";
import LegalPage from "../views/Legal/Legal";
import MintPage from "../views/Mint/Mint";
import ProvenancePage from "../views/Provenance/Provenance";

const PagesRoutes = () =>
  useRoutes([
    { path: "/", element: <Landing /> },
    {
      path: "/mint",
      element: <MintPage isWhitelist={false} maxMintPerWallet={20} />,
    },
    {
      path: "/provenance",
      element: <ProvenancePage />,
    },
    {
      path: "/terms",
      element: <LegalPage />,
    },
  ]);

export default PagesRoutes;
