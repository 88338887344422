import "./Parallax.scss";
import Eagle from "../../assets/eagles/eagle-with-jacket.png";

const Parallax = (): JSX.Element => {
  return (
    <>
      <section id="parallax-page">
        <div className="row text-center">
          <div className="col">
            <div className="space-100 d-none d-md-block"></div>
            <div className="space-60 d-md-none"></div>
            <h1>Welcome to the</h1>
            <h2><span>Crypto</span> Eagles Club</h2>
            <img className="img-eagle d-none d-md-block" alt="cryptoeaglesclub" width={400}  src={Eagle} />
            <div className="space-180 d-md-none"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Parallax;
