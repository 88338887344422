import { Divider } from "@mui/material";
import AboutPage from "../../ui-elements/about/About";
import CtaPage from "../../ui-elements/cta/Cta";
import Footer from "../../ui-elements/footer/Footer";
import Header from "../../ui-elements/menu/Menu";
import MintPage from "../../ui-elements/mint/Mint";
import Parallax from "../../ui-elements/parallax/Parallax";
import RoadMapPage from "../../ui-elements/roadmap/Roadmap";
import TeamPage from "../../ui-elements/team/Team";
import FaqPage from "../../ui-elements/faq/faq";
import SlickElement from "../../ui-elements/slick/slick";

import "./Landing.css";
import Configuration from "../../assets/config/config.json";
import { useEffect, useState } from "react";
import { ContractContext } from "../../types/types";

const Landing = () => {
  const [Config, setConfig] = useState<ContractContext>({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    IS_WHITE_LISTED: false,
    AVAILABLE_MINT: false,
    PRESALE_STARTED: false,
  });

  const getConfig = async () => {
    const config: ContractContext = await Configuration;
    setConfig(config);
  };

  useEffect(() => {
    getConfig();
  }, [Config]);

  return (
    <>
      <div id="home">
        <Header />
        <Parallax />
        <SlickElement />
        <MintPage
          isWhiteListPeriod={Config.IS_WHITE_LISTED}
          availableForMinting={Config.AVAILABLE_MINT}
          presale={Config.PRESALE_STARTED}
        />
        <div className="space-100" />
        <AboutPage />
        <div className="space-80" />
        <RoadMapPage />
        <div className="space-100" />
        <Divider
          sx={{
            borderColor: "white",
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <FaqPage />
        <TeamPage />
        <div className="space-100" />
        <CtaPage
          isWhiteListPeriod={Config.IS_WHITE_LISTED}
          availableForMinting={Config.AVAILABLE_MINT}
          openSeaLink={Config.MARKETPLACE_LINK}
          presale={Config.PRESALE_STARTED}
        />
        <div className="space-50 d-none d-md-block" />
        <Footer />
        <a
          href="https://discord.gg/hBfTdHsMHD"
          target="_blank"
          className="discord-button w-inline-block"
        >
          <div className="circle-small">
            <img
              src="https://uploads-ssl.webflow.com/61d6f4fdf4ef716e1125f693/61d7ed8a86aa6f029ca044f3_discord.png"
              loading="lazy"
              width="46.5"
              alt="Discord"
            />
          </div>
        </a>
      </div>
    </>
  );
};

export default Landing;
