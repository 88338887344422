import "./Footer.scss";
import Logo from "../../assets/icons/logo-footer2.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PartnerIcon from "../../assets/icons/rarity.png";

const Footer = (): JSX.Element => (
  <>
    <footer id="footer-page" className="footer">
      <div className="container-fluid footer-line">
        <hr className="p-0 line" />
        <div className="row mx-0 footer-padding">
          <div className="col-12 col-lg-4 order-first">
            <div className="space-50"></div>
            <h3>Need some help?</h3>
            <h5>Find the answers on our Discord</h5>
            <a
              href="https://discord.gg/hBfTdHsMHD"
              target="_blank"
              id="discord-btn"
              className="dicord-button-footer w-inline-block"
            >
              <button type="button" className="btn btn-primary">
                Join us on Discord
              </button>
            </a>
            {/*<h3 className="partner-title">As seen on</h3>*/}
            <a target="_blank" href="https://raritysniper.com/nft-drops-calendar/">
              <img style={{position: "relative", top: -0, left: 10}}  src={PartnerIcon} width={100} />
            </a>
          </div>
          <div className="col-12 col-lg-4 order-first">
            <div className="space-30"></div>
            <img
              className="img-fluid footer-logo"
              src={Logo}
              alt="logo"
              width={280}
            />
          </div>
          <div className="order-last my-auto text-center col-lg-4 col-sm-12 col-12">
            <div className="row">
              <div id="social" className="text-lg-right col-sm-12 col-12">
                <a target="_blank" href="https://www.instagram.com/cryptoeaglesclub/">
                  <InstagramIcon sx={{ color: "white" }} />
                </a>
                <a id="discord-btn" target="_blank" href="https://discord.gg/hBfTdHsMHD">
                  <img width={28} src="https://uploads-ssl.webflow.com/61d6f4fdf4ef716e1125f693/61dfe3c5cbbc7fc0501e4f76_picto-discord.svg" />
                </a>
                <a target="_blank" href="https://twitter.com/TheCryptoEagles">
                  <TwitterIcon sx={{ color: "white" }} />
                </a>
              </div>
              <div className="col-lg-12 col-sm-6 col-6">
                <p className="copyright text-right">
                  <span className="copy-left">© 2021 SYFTE, LLC</span>
                </p>
              </div>
              <div className="col-lg-12 col-sm-6 col-6">
                <p id="terms" className="copyright text-right">
                  <a className="link" href="/terms">
                    Terms and conditions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0">
        <span className="last-line"></span>
      </div>
    </footer>
  </>
);

export default Footer;
