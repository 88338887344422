import "./About.scss";
import Eagles from "../../assets/eagles/eagles3.gif";
import BioPage from "../bio/Bio";

const AboutPage = (): JSX.Element => {
  return (
    <>
      <section id="about-page">
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <h1>
                About the <span>project</span>
              </h1>
              <p>
                The Crypto Eagles Club is a collection of 9999 eagles NFTs as
                unique digital collectibles living on the Ethereum network. Each
                Crypto Eagle grants access to an special members benefits.
              </p>
              <p>
                Each Crypto Eagle is generated from over 130 possible traits,
                including clothing, colors, tattoos, headwear, and super rares
                elements. Most of Eagles can be bandits, gangster, Drug Dealers
                and some of them are super rares.
              </p>
              <p>
                The Eagles are stored as ERC-721 tokens on the Ethereum network
                and hosted on IPFS, we'll launch different sales with a max
                final cost of 0.030 ETH.
              </p>
              <p className="note text-justify mb-0">
                Note: Ten Crypto Eagles are being withheld from the sale. These
                will be used for giveaways, rewards—and for the creators' TCEC
                memberships.
              </p>
            </div>
            <div className="col">
              <img width={400} src={Eagles} alt="thecryptoeaglesclub" />
            </div>
          </div>
          <div className="space-80" />
          <BioPage />
        </div>
      </section>
    </>
  );
};

export default AboutPage;
