import { MintPageProps, SnackBarProps } from "../../types/interfaces";
import Logo from "../../assets/icons/logo.png";
import "./Mint.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContractContext } from "../../types/types";
import { fetchData } from "../../redux/data/dataActions";
import fileConfig from "../../assets/config/config.json";
import { connect } from "../../redux/blockchain/blockchainActions";
import { Alert, Snackbar } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const validationSchema = yup.object({
  quantity: yup.number().required("Please select a valid quantity"),
});

const AlertComponent = ({
  Message,
  duration,
  isError,
  open,
  setOpen,
}: SnackBarProps): JSX.Element => (
  <Snackbar
    onClose={() => setOpen(false)}
    open={open}
    autoHideDuration={duration}
    id="minted-popup"
  >
    <Alert severity={isError ? "error" : "success"}>{Message}</Alert>
  </Snackbar>
);

const WarningWhitelistComponent = ({ open }: SnackBarProps): JSX.Element => (
  <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    open={open}
  >
    <Alert severity="warning">
      Mint is only available for VIP Access, you can mint if you're at
      whitelisted, otherwise you can wait on discord for the pre-sale.
    </Alert>
  </Snackbar>
);

const MintPage: React.FC<MintPageProps> = ({
  maxMintPerWallet,
  isWhitelist,
}) => {
  ////////////////////////////////////////////// Metamask ////////////////

  const dispatch = useDispatch();
  const blockchain = useSelector((state: any) => state.blockchain);
  const data = useSelector((state: any) => state.data);
  const [mintingNft, setMintingNft] = useState<boolean>(false);
  const [salePaused, setSalePaused] = useState<boolean>(false);
  const [revealed, setRevealed] = useState<boolean>(false);
  const [onlyWhitelisted, setOnlyWhitelisted] = useState<boolean>(false);
  const [maxMintAmount, setMaxMintAmount] = useState<number>(1); // Default 5
  const [totalSupply, setTotalSupply] = useState<string>("0");
  const { width } = useWindowDimensions();
  const [msgFeedback, setMsgFeedback] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [Cost, setCost] = useState<number>(0);
  const [whiteListSnackBar, setWhiteListSnackBar] = useState<boolean>(false);
  const [mintingFailed, setMintingFailed] = useState<boolean>(false);
  const [Config, setConfig] = useState<ContractContext>({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    IS_WHITE_LISTED: false,
    AVAILABLE_MINT: true,
  });

  const getConfig = async () => {
    const config: ContractContext = await fileConfig;
    setConfig(config);
  };

  const truncate = (input: string, len: number) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

  useEffect(() => {
    getConfig();
    //console.log(Config);
  }, [Config]);

  const getSmartContractData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      setRevealed(data.revealed);
      setSalePaused(data.paused);
      setOnlyWhitelisted(data.onlyWhitelisted);
      setMaxMintAmount(Number(data.maxMintAmount));
      setCost(Number(data.cost));
      setTotalSupply(data.totalSupply);
      if (onlyWhitelisted) {
        setWhiteListSnackBar(true);
      }
    }
  }, [data]);

  useEffect(() => {
    getSmartContractData();
  }, [blockchain.account]);

  const handleWalletConnect = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(connect());
    getSmartContractData();
  };

  ///////////////////////////////////////////////

  const [qtyMint, setQtyMint] = useState<number>(1);

  const mintNFT = () => {
    let cost = Cost;
    let gasLimit = Config.GAS_LIMIT;
    let totalCostWei = String(cost * qtyMint);
    let totalGasLimit = String(gasLimit * qtyMint);
    //console.log(`Cost ${totalCostWei}`);
    //console.log(`Gas limit: ${totalGasLimit}`);
    //console.log(data);
    setMintingNft(true);
    blockchain.smartContract.methods
      .mint(qtyMint)
      .send({
        gasLimit: String(totalGasLimit),
        to: Config.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err: any) => {
        console.log(err);
        setMsgFeedback(err.message);
        setMintingNft(false);
        setMintingFailed(true);
        setOpenSnackbar(true);
      })
      .then((receipt: any) => {
        //console.log(receipt);
        setMsgFeedback(
          `Congratz!, the ${Config.NFT_NAME} is yours, go visit Opensea.io to view it.`
        );
        setMintingNft(false);
        setMintingFailed(false);
        setOpenSnackbar(true);
        setTotalSupply(Number(totalSupply + qtyMint).toString());
        dispatch(fetchData(blockchain.account));
      });
  };

  const formik = useFormik({
    initialValues: {
      quantity: qtyMint,
    },
    validationSchema,
    onSubmit: (values) => {
      // Dispara algo
    },
  });

  const handleQtySumMint = () => {
    if (qtyMint < maxMintAmount) {
      setQtyMint(qtyMint + 1);
    }
  };
  const handleQtySubMint = () => {
    if (qtyMint >= 2) {
      setQtyMint(qtyMint - 1);
    }
  };

  const handleMintAction = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    mintNFT();
    getSmartContractData();
  };

  useEffect(() => {
    if (qtyMint <= maxMintPerWallet && qtyMint >= 0) {
      formik.values.quantity = qtyMint;
    }
  }, [qtyMint]);

  return (
    <>
      <section id="mint-page2">
        <nav
          id="header"
          className="navbar navbar-expand-lg navbar-light bg-transparent"
        >
          <div className="container-fluid">
            <a
              style={{ cursor: "pointer !important" }}
              className="navbar-brand text-center"
              href="/"
            >
              <img
                src={Logo}
                width={160}
                alt="cryptoeaglesclub-logo"
                className="d-none d-lg-block"
              />
            </a>
            <div
              className="collapse navbar-collapse justify-content-center d-none d-md-none"
              id="navbarNav"
            >
              {blockchain.account === null &&
              blockchain.smartContract === null ? (
                <button
                  onClick={(e) => handleWalletConnect(e)}
                  style={{
                    position: "absolute",
                    right: 0,
                    marginRight: 40,
                    width: "auto",
                    borderRadius: 20,
                    fontSize: 18,
                    marginTop: "10px",
                  }}
                  id="wallet-btn"
                  type="button"
                  className="btn-primary"
                >
                  Connect Wallet
                </button>
              ) : (
                <button
                  style={{
                    position: "absolute",
                    right: 0,
                    marginRight: 40,
                    width: "auto",
                    borderRadius: 20,
                    fontSize: 18,
                    marginTop: "10px",
                  }}
                  disabled
                  type="button"
                  className="btn-primary"
                >
                  {truncate(blockchain.account, 15)}
                </button>
              )}
            </div>
            <div className="d-lg-none text-center">
              <a className="navbar-brand text-center mobile-logo" href="/">
                <img src={Logo} width={160} alt="cryptoeaglesclub-logo" />
              </a>
              <div className="space-50"></div>
              {blockchain.account === null &&
              blockchain.smartContract === null ? (
                <button
                  onClick={(e) => handleWalletConnect(e)}
                  id="wallet-btn"
                  style={{
                    width: "auto",
                    borderRadius: 20,
                    fontSize: 18,
                    marginTop: "10px",
                    position: "relative",
                    top: "-30px",
                  }}
                  type="button"
                  className="btn-primary"
                >
                  Connect Wallet
                </button>
              ) : (
                <button
                  style={{
                    position: "absolute",
                    right: 0,
                    marginRight: 40,
                    width: "auto",
                    borderRadius: 20,
                    fontSize: 18,
                    marginTop: "-10px",
                  }}
                  disabled
                  type="button"
                  className="btn-primary"
                >
                  {truncate(blockchain.account, 8)}
                </button>
              )}
            </div>
          </div>
        </nav>
        <div className="space-100" />
        {blockchain.account === null &&
        blockchain.smartContract === null &&
        width <= 481 ? (
          <div className="space-100" />
        ) : (
          <div className="space-25" />
        )}
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <div className="card card-club">
                <div className="card-body">
                  <h5 className="card-title">
                    Mint an <span>Crypto Eagle</span> using your Ethereum wallet
                  </h5>
                  <div className="space-15"></div>
                  <div className="line"></div>
                  <div className="wrapper-price-mint">
                    <div className="text">Mint price :</div>
                    <img
                      src="https://uploads-ssl.webflow.com/61d6f4fdf4ef716e1125f693/61e587de835c148246da7ec5_eth.png"
                      loading="eager"
                      width="24"
                      alt="etherum"
                    />
                    <div className="text price">{Config.DISPLAY_COST}</div>
                  </div>
                  <div className="line"></div>
                  {blockchain.account !== null &&
                  blockchain.smartContract !== null ? (
                    <>
                      {revealed && !salePaused ? (
                        <form noValidate onSubmit={formik.handleSubmit}>
                          <div className="space-15"></div>
                          <h3 className="form-label">Quantity</h3>
                          <h6 className="supply-title">
                            First wave of Crypto Eagles:{" "}
                            <span className="supply-label">
                              {totalSupply} / 1399
                            </span>
                          </h6>
                          <div className="space-20"></div>
                          <div
                            style={{ position: "relative", left: 10 }}
                            className="input-group mb-3 text-center offset-lg-2 offset-xl-3"
                          >
                            <div className="input-group-text eagle-round">
                              <button
                                onClick={() => handleQtySubMint()}
                                className="btn-primary btn-form"
                              >
                                -
                              </button>
                            </div>
                            <input
                              required
                              className="form-control"
                              type="number"
                              name="quantity"
                              disabled
                              value={qtyMint}
                              onChange={formik.handleChange}
                            />
                            <div className="input-group-text eagle-round">
                              <button
                                onClick={(e) => handleQtySumMint()}
                                className="btn-primary btn-form"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          {!salePaused && (
                            <button
                              onClick={(e) => handleMintAction(e)}
                              disabled={mintingNft}
                              id="btn-submit"
                              className="btn-primary offset-lg-1 ml-auto mr-auto btn-conversion"
                              type="submit"
                            >
                              Mint now
                            </button>
                          )}
                          <div className="space-30"></div>
                        </form>
                      ) : (
                        <>
                          <h1 className="helper-text">
                            The project hasn't been revealed yet or the sale is
                            not available for now.
                          </h1>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <h1 className="helper-text">
                        Connect your Metamask first!
                      </h1>
                      <h6 className="link-metamask">
                            <a href="https://metamask.app.link/dapp/cryptoeaglesclub.com">Minting on mobile? Press here to mint on the Metamask app instead.</a>
                      </h6>
                    </>
                  )}
                  <AlertComponent
                    Message={msgFeedback}
                    open={openSnackbar}
                    duration={mintingFailed ? 7000 : 4000}
                    isError={mintingFailed}
                    setOpen={setOpenSnackbar}
                  />
                  {onlyWhitelisted && (
                    <WarningWhitelistComponent
                      open={whiteListSnackBar}
                      setOpen={setWhiteListSnackBar}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MintPage;
