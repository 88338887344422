import Header from "../../ui-elements/menu/Menu";
import "./Legal.scss";

const LegalPage = () => (
  <>
    <section id="legal-page">
      <Header />
      <div className="space-150"></div>
      <div className="container">
        <div className="row text-center">
          <div className="col">
            <div className="col-12">
              <h1>TERMS &amp; CONDITIONS</h1>
              <p className="terms-description">
                The Crypto Eagles Club is a collection of digital artworks
                (NFTs) running on the Ethereum network. This website is only an
                interface allowing participants to exchange digital
                collectibles. Users are entirely responsible for the safety and
                management of their own private Ethereum wallets and validating
                all transactions and contracts generated by this website before
                approval. Furthermore, as the The Crypto Eagles Club smart
                contract runs on the Ethereum network, there is no ability to
                undo, reverse, or restore any transactions. This website and its
                connected services are provided “as is” and “as available”
                without warranty of any kind. By using this website you are
                accepting sole responsibility for any and all transactions
                involving The Crypto Eagles digital collectibles.
              </p>
              <h1>OWNERSHIP</h1>
              <p className="terms-description">
                i. You Own the NFT. Each Crypto Eagle is an NFT on the Ethereum
                blockchain. When you purchase an NFT, you own the Crypto Eagle,
                the Art, completely. Ownership of the NFT is mediated entirely
                by the Smart Contract and the Ethereum Network: at no point may
                we seize, freeze, or otherwise modify the ownership of any
                Crypto Eagle. ii. Personal Use. Subject to your continued
                compliance with these Terms, SYFTE LLC grants you a worldwide,
                royalty license to use, copy, and display the purchased Art,
                along with any extensions that you choose to create or use,
                solely for the following purposes: (i) for your own personal,
                non-commercial use;
              </p>
              <p className="terms-description">
                (ii) as part of a marketplace that permits the purchase and sale
                of your Crypto Eagle / NFT, provided that the marketplace
                cryptographically verifies each Crypto Eagle owner’s rights to
                display the Art for their Crypto Eagle to ensure that only the
                actual owner can display the Art; or (iii) as part of a third
                party website or application that permits the inclusion,
                involvement, or participation of your Crypto Eagle, provided
                that the website/application cryptographically verifies each
                Crypto Eagle owner’s rights to display the Art for their Eagles
                to ensure that only the actual owner can display the Art, and
                provided that the Art is no longer visible once the owner of the
                Crypto Eagle leaves the website/application.
              </p>
              <p className="terms-description">
                iii. Commercial Use. Subject to your continued compliance with
                these Terms, SYFTE LLC grants you an unlimited, worldwide
                license to use, copy, and display the purchased Art for the
                purpose of creating derivative works based upon the Art
                (“Commercial Use”). Examples of such Commercial Use would e.g.
                be the use of the Art to produce and sell merchandise products
                (T-Shirts etc.) displaying copies of the Art. For the sake of
                clarity, nothing in this Section will be deemed to restrict you
                from (i) owning or operating a marketplace that permits the use
                and sale of Crypto Eagles generally, provided that the
                marketplace cryptographically verifies each Crypto Eagle owner’s
                rights to display the Art for their Crypto Eagle to ensure that
                only the actual owner can display the Art; (ii) owning or
                operating a third party website or application that permits the
                inclusion, involvement, or participation of Crypto Eagles
                generally, provided that the third party website or application
                cryptographically verifies each Eagle owner’s rights to display
                the Art for their Crypto Eagle to ensure that only the actual
                owner can display the Art, and provided that the Art is no
                longer visible once the owner of the Purchased Crypto Eagle
                leaves the website/application; or (iii) earning revenue from
                any of the foregoing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default LegalPage;
